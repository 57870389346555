importScripts('dexie.min.js');
importScripts('SharedDBVersions.js');

const url = new URL(location.href);
if (url.hostname.includes('shkl.co.il')) {
  require('workbox-sw');
  workbox.setConfig({ debug: url.hostname == "localhost", modulePathPrefix: "/workbox_files" });
} else {
  importScripts('https://storage.googleapis.com/workbox-cdn/releases/6.4.1/workbox-sw.js')
  workbox.setConfig({ debug: url.hostname == "localhost" });
}

const getPosParamsRoute = '/getposparams';
var db = new Dexie('positive');
var catalogInProgress = null;
var dataTypesInProgress = {};

positiveDBVersions(db);

// workbox.skipWaiting();
// workbox.clientsClaim();

workbox.precaching.precacheAndRoute(self.__WB_MANIFEST, {
  ignoreURLParametersMatching: [/^utm_/, /\^?/]
});

workbox.routing.registerRoute(
  new RegExp('assets\/queueWorker.js'),
  new workbox.strategies.CacheFirst()
);
workbox.routing.registerRoute(
  new RegExp("main\\..*\\.js$"),
  new workbox.strategies.CacheFirst()
);

let imageCaches = {};
let itemImageCache = new Map();

function clearImageCaches() {
  imageCaches = {};
  itemImageCache = new Map();
}


async function fetchAndConvertToBase64(url) {
  let fetchedResponse = await fetch(url);
  let blob = await fetchedResponse.blob();
  let promise = new Promise(resolve => {
    let reader = new FileReader();
    reader.onload = function () { resolve(this.result) };
    reader.readAsDataURL(blob);
  });

  return await promise;
}

function cacheImagesAndReturnFromCache(event, urlContains, tableName, tablePK) {
  if (event.request.url.includes(urlContains)) {
    if (!imageCaches[tableName]) {
      imageCaches[tableName] = {};
    }

    if (imageCaches[tableName][event.request.url]) {
      event.respondWith(fetch(imageCaches[tableName][event.request.url]));
      return;
    }

    let dbImageUrl = decodeURI(event.request.url.substr(event.request.url.lastIndexOf('/') + 1));

    const responsWithFunction = async () => {
      let dbRes = await db[tableName].where(tablePK).equals(dbImageUrl).toArray();

      if (dbRes.length > 0) {
        imageCaches[tableName][event.request.url] = dbRes[0].base64;
        return await fetch(dbRes[0].base64) // return the base 64 image into the pos
      } else {
        let base64 = await fetchAndConvertToBase64(event.request.url);
        imageCaches[tableName][event.request.url] = base64;
        db[tableName].put({ [tablePK]: dbImageUrl, base64: base64 });

        return await fetch(base64); // return the base 64 image into the pos
      }
    };

    event.respondWith(responsWithFunction());
  }
}

self.addEventListener('fetch', function (event) {

  if (event.request.url.includes("catalog-images/items")) {
    if (itemImageCache.has(event.request.url)) {
      event.respondWith(fetch(itemImageCache.get(event.request.url)))
      return;
    }

    var itemCode = event.request.url.substr(event.request.url.lastIndexOf('/') + 1);
    event.respondWith(
      db.itemPictures.where('item_id').equals(decodeURI(itemCode)).toArray().then((res) => {
        if (res.length > 0) {
          itemImageCache.set(event.request.url, res[0].base64)
          return fetch(res[0].base64)
        }
        else {
          //The Tiniest GIF Ever
          return fetch('data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==')
        }
      })
    )
  }

  // When the item will be converted appropriatly, use this
  // the problem now is that the url is not the currect url for the picture asset
  //cacheImagesAndReturnFromCache(event, "catalog-images/items", 'itemPictures', 'item_id');
  cacheImagesAndReturnFromCache(event, "catalog-images/images", 'images', 'pictureUrl');

})
self.addEventListener('message', (event) => {
  if (!event.data) {
    return;
  }

  switch (event.data.type) {
    case 'skipWaiting':
      self.skipWaiting();
      break;
    case 'catalogFinishedSuccessfully':
      clearImageCaches();
      break;
    default:
      // NOOP
      break;
  }
});


async function getPosParamsFromCache() {
  let getPosParamsURL = new URL(url.origin + getPosParamsRoute);
  const response = await caches.match(getPosParamsURL);
  if (response === undefined) {
    return null;
  } else {
    let params = await response.json();
    return params;
  }
}
async function awaitSafely(promise) {
  try {
    return await promise;
  } catch (error) { }
}
/**
 * @param {{ url: URL|string, method: string }} args 
 * @returns {Promise<Request>}
 */
async function createRequestToPosit(args) {
  let posParams = await getPosParamsFromCache();
  if (!posParams) {
    return null;
  }
  let accessToken = posParams.access_token;
  let headers = { 'Authorization': `Token token=${accessToken}` }
  let url = typeof (args.url) === "string" ? new URL(args.url) : args.url;
  return new Request(url, { headers: headers, method: args.method || 'GET' });
}
async function shouldCacheCatalog() {
  let posParams = await getPosParamsFromCache();
  if (posParams) {
    let jsonConfig = JSON.parse(posParams.json_config || '{}');
    return Boolean(!jsonConfig.on_demand_catalog && jsonConfig.shouldCacheCatalog);
  }
  return false;
}
/**
 * @param {{ url: URL } } args
 * @returns {Boolean}
 */
function catalogRequestsOnly({ url }) {
  if (url.pathname.includes('get_catalog_on_demand')) return false; // Always fetch from Network
  let regex = /^\/catalog\/\S+/g; // Starts with '/catalog/' and must have after that at least 1 non-white char
  return regex.test(url.pathname);
};
/**
 * @param {Request} request 
 * @returns {Promise<Response>}
 */
async function getOrAddToCacheViaCheckSum(request) {
  let url = new URL(request.url);
  let cacheName = url.pathname;
  let route = url.pathname.split('/');
  route = `/${route[1]}/last_catalog_success_finish_time/${route[2]}`;
  let lastCatalogSuccessFinishTimeURL = new URL(url.origin + route + url.search);
  let lastCatalogSuccessFinishTimeReq = await createRequestToPosit({ method: 'GET', url: lastCatalogSuccessFinishTimeURL })

  let serverStatusRes = await fetch(lastCatalogSuccessFinishTimeReq);
  let serverStatusResForSaving = serverStatusRes.clone();
  let serverStatus = new Date();
  if (serverStatusRes.ok) {
    let serverLastCatalogSuccessFinishTime = await serverStatusRes.text();
    if (serverLastCatalogSuccessFinishTime) serverStatus = new Date(serverLastCatalogSuccessFinishTime);
  }

  let cachedStatusRes = await caches.match(lastCatalogSuccessFinishTimeURL);
  let cachedStatus = new Date(1600, 1);
  if (cachedStatusRes) {
    let cachedLastCatalogSuccessFinishTime = await cachedStatusRes.text();
    if (cachedLastCatalogSuccessFinishTime) cachedStatus = new Date(cachedLastCatalogSuccessFinishTime);
  }

  let hasCache = await caches.has(cacheName);
  if (hasCache && cachedStatus >= serverStatus) { // Just Send the cached, there's no change
    console.info(`WorkBox ::: Catalog data for ${cacheName} hasn't changed, get cached data`);
    const response = await caches.match(url);
    return response;
  } else { // Data change, update cache
    console.info(`WorkBox ::: Catalog data for ${cacheName} has changed, get new data`);
    const response = await fetch(request);
    if (response.ok) {
      let cache = await caches.open(cacheName);
      cache.put(url, response.clone());
      cache.put(lastCatalogSuccessFinishTimeURL, serverStatusResForSaving);
    } else {
      console.info(`WorkBox ::: Couldn't fetch Catalog data for ${cacheName} from Network, No caching will occur`);
    }
    return response;
  }
}
/**
 * @param {{ url: URL, request: Request, event: any, params: any } } args
 * @returns {Promise<Response>}
 */
async function latestOrCacheFirst({ url, request, event, params }) {
  let shouldCache = await shouldCacheCatalog();

  if (!shouldCache) { // Continue as usual
    console.info('Wordbox ::: Catalog should not be cached, continue as usual');
    const response = await fetch(request);
    return response;
  }

  const cacheName = url.pathname;
  if (dataTypesInProgress[cacheName]) {
    console.info(`Wordbox ::: catalog job for ${cacheName} in progress... wait`);
    await awaitSafely(dataTypesInProgress[cacheName]);
  }
  dataTypesInProgress[cacheName] = getOrAddToCacheViaCheckSum(request);
  return await dataTypesInProgress[cacheName];
};
async function getCatalogInBackground() {
  console.info('getCatalogInBackground ::: START');
  let shouldCache = await shouldCacheCatalog();

  if (!shouldCache) { // Continue as usual
    catalogInProgress = setTimeout(() => { getCatalogInBackground() }, (60 * 1000) * 30) // Every half hour
    return;
  }

  let posParams = await getPosParamsFromCache(); // From here, start background work
  let isJsonDumpFunc = dumpName => ["item-pictures", "positive-customer", "secondary-category", "screen-savers", "images", "videos"].includes(dumpName);
  let isDbDataFileDumpFunc = dumpName => dumpName == 'wasm';

  let cacheNames = await caches.keys();
  cacheNames = cacheNames.filter(k => k.startsWith('/catalog/'));
  for (const cacheName of cacheNames) {
    if (dataTypesInProgress[cacheName]) {
      console.info(`Wordbox ::: catalog job for ${cacheName} in progress... wait`);
      await awaitSafely(dataTypesInProgress[cacheName]);
    }
    let isJsonDump = isJsonDumpFunc(cacheName.replace('/catalog/', ''));
    let isDbDataFileDump = isDbDataFileDumpFunc(cacheName.replace('/catalog/', ''));

    let catalogUrl = new URL(url.origin + cacheName);
    catalogUrl.searchParams.append('pos_device_id', posParams.pos_device_id);
    catalogUrl.searchParams.append("data_ext", isJsonDump ? "json" : isDbDataFileDump ? "db" : "dump");

    try {
      let request = await createRequestToPosit({ url: catalogUrl, method: 'GET' });
      dataTypesInProgress[cacheName] = getOrAddToCacheViaCheckSum(request);
      await dataTypesInProgress[cacheName];
    } catch (error) {
      console.error('Error during background catalog update :: ', error);
      break; // This is mostly likely because DB is closed, so best to stop this operation and wait for the next.
    }
  }
  console.info('getCatalogInBackground ::: END');
  catalogInProgress = setTimeout(() => { getCatalogInBackground() }, (60 * 1000) * 30) // Every half hour
}
getCatalogInBackground();
workbox.routing.registerRoute(catalogRequestsOnly, latestOrCacheFirst);

/**
 * @param {{ url: URL, request: Request, event: any, params: any } } args
 * @returns {Promise<Response>}
 */
async function saveInCacheIfOkAndContinue({ url, request, event, params }) {
  console.info('Workbox :: caching pos params')
  const response = await fetch(request);
  if (response.ok) {
    let cache = await caches.open(getPosParamsRoute);
    cache.put(url, response.clone());
  }
  return response;
};
workbox.routing.registerRoute(getPosParamsRoute, saveInCacheIfOkAndContinue, 'POST');

async function clearCatalogData({ request }) {
  let cacheNames = Object.keys(dataTypesInProgress);
  for (const cacheName of cacheNames) {
    let deleted = await awaitSafely(caches.delete(cacheName)) || false;
    console.info(`Cache ${cacheName} ${deleted ? 'was deleted successfull' : "couldn't be found/deleted"}`);
  }
  let deleted = await awaitSafely(caches.delete(getPosParamsRoute));
  console.info(`Cache posParams ${deleted ? 'was deleted successfull' : "couldn't be found/deleted"}`);
  return await fetch(request); // continue request regardless
}
workbox.routing.registerRoute('/clear_physical_id/', clearCatalogData, 'POST');

// This shit is in comment for now so the QA could test this feature
// workbox.routing.registerRoute( // dwenou3jb is the cloudinary account name
//   ({url}) => url.origin === 'https://res.cloudinary.com' && url.pathname.startsWith('/dweonu3jb/video/upload/'),
//   new workbox.strategies.CacheFirst({
//     cacheName: 'cloudinary-videos',
//     plugins: [
//       new workbox.expiration.ExpirationPlugin({
//         maxEntries: 50, // Limit the number of videos to prevent storage issues
//         maxAgeSeconds: 30 * 24 * 60 * 60, // Cache for 30 Days
//       }),
//       new workbox.cacheableResponse.CacheableResponsePlugin({
//         statuses: [0, 200],
//       }),
//       new workbox.rangeRequests.RangeRequestsPlugin(),
//     ],
//   })
// );